import React from 'react';

function ResumeLink() {
    return (
        <a href="https://www.linkedin.com/in/christinareannaknapp/overlay/1733792022539/single-media-viewer/?profileId=ACoAAC7u2lgB8uhNjH4B5W1K6lg6HVZwU9Mpm0E" target="_blank" rel="noreferrer">
            View my resume on LinkedIn.
        </a>
    );
}

export default ResumeLink;